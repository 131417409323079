window.jQuery = window.$ = require('jquery');
var processInclude = require('./util');

$(document).ready(function () {
    processInclude(require('./components/menu'));
    processInclude(require('./components/cookie'));
    processInclude(require('./components/consentTracking'));
    processInclude(require('./components/footer'));
    processInclude(require('./components/miniCart'));
    processInclude(require('./components/collapsibleItem'));
    processInclude(require('./components/search'));
    processInclude(require('./components/clientSideValidation'));
    processInclude(require('./components/countrySelector'));
    processInclude(require('./components/toolTip'));
    processInclude(require('./components/giftCert'));
    processInclude(require('./slickCarousel'));
    processInclude(require('int_geolocateredirect/redirectpopin/redirectpopin'));
    processInclude(require('./components/newsletterPopin'));
    processInclude(require('./account/dashboard/nav'));
    processInclude(require('./gtmCookie/gtmCookie'));
});

require('jquery-ui-dist/jquery-ui');
require('./components/spinner');
require('./thirdParty/bootstrap');
require('./thirdParty/slick-carousel');
require('./thirdParty/bootstrap-show-password');
